import { MaybeElement } from "@blueprintjs/core";
import {
  HexVersionId,
  ImageAlignment,
  ImageElement,
  RichTextImage,
  updateFileUrlsInSource,
} from "@hex/common";
import { findNode, setNodes, useEditorRef } from "@udecode/plate-common";
import React, { useMemo } from "react";
import { useReadOnly, useSelected } from "slate-react";
import styled from "styled-components";

import { HexButton } from "../../../../hex-components";
import {
  AlignHorizontalCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
} from "../../../icons/CustomIcons";
import { TRenderCustomElementProps } from "../../elementRenderers.js";

import { ResizableImage } from "./ResizableImage";

export function RichTextImageElement({
  attributes,
  children,
  element,
  hexVersionId,
}: TRenderCustomElementProps & { hexVersionId?: HexVersionId }): JSX.Element {
  const isSelected = useSelected();
  const editor = useEditorRef();
  const isReadOnly = useReadOnly();

  const imageSrc = useMemo(() => {
    if (ImageElement.guard(element)) {
      return hexVersionId
        ? updateFileUrlsInSource(hexVersionId, element.src)
        : element.src;
    }
    return "";
  }, [element, hexVersionId]);

  const imageElement: ImageElement | null = useMemo(() => {
    if (ImageElement.guard(element)) {
      return { ...element, src: imageSrc };
    }
    return null;
  }, [element, imageSrc]);

  if (imageElement == null) {
    return <span {...attributes}>{children}</span>;
  }

  const onAlignmentClick = (alignment: ImageAlignment): void => {
    if (editor.selection != null && !isReadOnly) {
      const nodes = findNode(editor, {
        at: editor.selection,
        match: { type: RichTextImage.value },
      });
      const path = nodes?.[1];
      if (path != null) {
        setNodes(
          editor,
          {
            alignment:
              imageElement.alignment === alignment ? undefined : alignment,
          },
          { at: path },
        );
      }
    }
  };

  return (
    <Container
      {...attributes}
      $alignment={imageElement.alignment}
      contentEditable={false}
    >
      <ImageContainer>
        {isSelected && !isReadOnly && (
          <AlignmentButtons>
            {alignments.map((alignment) => (
              <HexButton
                key={alignment}
                active={imageElement.alignment === alignment}
                icon={alignmentIcons[alignment]}
                minimal={true}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => onAlignmentClick(alignment)}
              />
            ))}
          </AlignmentButtons>
        )}
        <ResizableImage
          imageElement={imageElement}
          isDisabled={isReadOnly}
          isSelected={isSelected}
        />
      </ImageContainer>
      {children}
    </Container>
  );
}

const alignments: [ImageAlignment, ImageAlignment, ImageAlignment] = [
  "left",
  "center",
  "right",
];

const alignmentIcons: Record<ImageAlignment, MaybeElement> = {
  left: <AlignLeftIcon />,
  center: <AlignHorizontalCenterIcon />,
  right: <AlignRightIcon />,
};

const Container = styled.span<{ $alignment?: ImageAlignment }>`
  position: relative;

  display: ${({ $alignment }) => ($alignment != null ? "flex" : "inline")};

  justify-content: ${({ $alignment }) =>
    $alignment === "left"
      ? "flex-start"
      : $alignment === "center"
        ? "center"
        : "flex-end"};

  margin: 8px 4px 12px;
`;

const ImageContainer = styled.span`
  position: relative;

  display: inline-block;
`;

const AlignmentButtons = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;

  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};
`;
