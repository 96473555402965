import { ImageElement, RichTextImage } from "@hex/common";
import { findNode, setNodes, useEditorRef } from "@udecode/plate-common";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { DragHandleUI } from "../../../hex/ResizeDragHandle";

const DEFAULT_IMAGE_WIDTH = 300;

export interface ResizableImageProps {
  imageElement: ImageElement;
  isSelected: boolean;
  isDisabled: boolean;
}

export const ResizableImage: React.ComponentType<ResizableImageProps> =
  React.memo(function ResizableImage({
    imageElement,
    isDisabled,
    isSelected,
  }: ResizableImageProps) {
    const [imageWidth, setImageWidth] = useState<number>(
      imageElement.width ?? DEFAULT_IMAGE_WIDTH,
    );
    const editor = useEditorRef();

    const onResizeEnd = useCallback(
      (newWidth: number) => {
        if (editor.selection != null && !isDisabled) {
          const nodes = findNode(editor, {
            at: editor.selection,
            match: { type: RichTextImage.value },
          });
          const path = nodes?.[1];
          if (path != null) {
            setNodes(editor, { width: newWidth }, { at: path });
          }
        }
      },
      [editor, isDisabled],
    );

    useEffect(() => {
      if (imageElement.width != null) {
        setImageWidth(imageElement.width);
      }
    }, [imageElement.width]);

    return (
      <ImageContainer $selected={isSelected}>
        <Image src={imageElement.src} width={imageWidth} />
        {isSelected && !isDisabled && (
          <>
            <DragHandle
              position="left"
              onResize={setImageWidth}
              onResizeEnd={onResizeEnd}
            />
            <DragHandle
              position="right"
              onResize={setImageWidth}
              onResizeEnd={onResizeEnd}
            />
          </>
        )}
      </ImageContainer>
    );
  });

function DragHandle({
  onResize,
  onResizeEnd,
  position,
}: {
  position: DragHandlePosition;
  onResize: React.Dispatch<React.SetStateAction<number>>;
  onResizeEnd: (width: number) => void;
}): JSX.Element {
  const [isDragging, setIsDragging] = useState(false);

  const onMouseDown = useCallback(() => {
    if (!isDragging) {
      setIsDragging(true);
    }
  }, [isDragging]);

  useEffect(() => {
    if (isDragging) {
      // keep of width to provide it to the onResizeEnd callback.
      // we keep `imageWidth` as state in ResizableImage and providing
      // `imageWidth` as a dependency to the onResizeEnd callback will
      // result in instantiating a new instance of onResizeEnd each time
      // the image is resized, which would result in the event listener
      // being added and removed repeated, which can lead to jerky behavior
      let width = 0;

      const onMouseUp = (): void => {
        setIsDragging(false);
        onResizeEnd(width);
      };
      const onMouseMove = (evt: MouseEvent): void => {
        onResize((curWidth) => {
          width = curWidth + evt.movementX * (position === "left" ? -1 : 1);
          return width;
        });
      };
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("mousemove", onMouseMove);
      return () => {
        document.removeEventListener("mouseup", onMouseUp);
        document.removeEventListener("mousemove", onMouseMove);
      };
    }
  }, [isDragging, onResize, onResizeEnd, position]);

  return (
    <DragHandleContainer $position={position} onMouseDown={onMouseDown}>
      <DragHandleUI />
    </DragHandleContainer>
  );
}

type DragHandlePosition = "left" | "right";

const ImageContainer = styled.span<{ $selected: boolean }>`
  position: relative;

  display: inline-block;

  ${({ $selected, theme }) =>
    $selected &&
    css`
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;

        box-shadow: inset 0 0 0 1px
          ${theme.dragAndDrop.selectedElementBorderColor};

        content: "";
      }
    `}
`;

const Image = styled.img`
  display: inline;

  cursor: pointer;
`;

const DragHandleContainer = styled.span<{ $position: DragHandlePosition }>`
  position: absolute;
  top: 50%;

  display: block;

  padding: 10px 5px;

  cursor: ew-resize;

  ${({ $position }) => `${$position}: -10px;`}
`;
